.snackbar{
  position: fixed;
  z-index: 4100;
  max-width: 500px;
  padding: 10px 20px;
  border-radius: 5px;
  top: 20px;
  right: 0;
  color: $white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, right 0.3s, visibility 0.3s;

  &.show{
    opacity: 1;
    visibility: visible;
    right: 20px;
  }

  &.ERROR{
    background-color: $error;
  }
  &.SUCCESS{
    background-color: $success;
  }
  &.WARNING{
    background-color: $warning;
  }
  &.INFO{
    background-color: $info;
  }
}