.agent-container{
  .create-agent-popup{
    width: 450px;
    max-width: 100%;

    .input-container{
      margin-bottom: 20px;

      label{
        font-weight: 600;
        margin-bottom: 8px;
      }

      input,
      select{
        height: 40px;
        border: 1px solid rgba($color: $black, $alpha: 0.2);
        border-radius: 4px;
        padding: 0 10px;
        &:focus{
          outline: none;
        }
      }
    }
  }

  table{
    margin-top: 20px;
  }
}