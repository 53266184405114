header{
  background-color: $white;
  height: 100px;
  //box-shadow: 0 0 5px 2px rgba($color: $black, $alpha: 0.5);
  box-shadow: 0px 30px 45px -30px rgba($color: $black, $alpha: 0.1);
  padding: 0 20px;

  .user-name{
    font-size: 0.9em;
    font-weight: 600;
    background-color:$brand-color-a;
    border-radius: 24px;
    padding:5px 14px 3px 8px;
    margin-bottom: 0px;
    color:#fff;
    box-shadow: 0px 6px 6px -8px rgba($color: $black, $alpha: 0.4);
    .user-icon{
      margin-right: 10px;
      height: 24px;
      color:$primary-color
    }
    .user-icon path{
      fill:#fff;
    }

}

 
  .menu-icon{
    cursor: pointer;
  }
  .nav-icons{
    height: 30px;
  }
 
  .nav-tab{
    height: 40px;
    padding:8px 20px;
    background-color: $white;
    text-decoration: none;
    color: $black;
    border: 1px solid #d2d2d2;
    margin:0px 10px;
    border-radius: 5px;
    &.active{
      background-color: $secondary-color;
      color: $white;
    }
    &:hover {
      background-color: $link-color;       
      border: 1px solid $link-color;
      color:#ffff;
    }
  }
   
 
  .sidebar-link{
    height: 10px;
    color: $black;
    font-weight: 500;
    text-decoration: none;
    margin-left: 20px;
    transition: all 0.3s;
    background-color: transparent;
    display: flex;
    align-items: center;
  
    &:last-child{
      margin-right: 0;
    }
    .icon path {
      //fill : $secondary-color
      color:$secondary-color
    }
    &:hover {
      .icon path {
        //fill: black; /* Change the color to black on hover */
         /* Increase the size on hover */
        color:$primary-color
      }
      // transform: scale(1.2);
    }
    
    &.active {
      background-color:$primary-color;
      padding:18px 6px 18px 10px;
      border-radius: 24px;
      .icon path {
        //fill: black; /* Change the color to black when active */
        color:$white
       
      }
      // transform: scale(1.2);
    }
    .icon {
      height: auto;
      width: 26px;
      margin-right: 5px;
      transition: transform 0.3s; 
    }
    .home-icon{
      height: 22px;
    }
    .settings-icon{
      height: 24px;
    }
    .logout-icon{
      height: 28px;
    }
    .help-icon{
      height: 28px;
    }
    .reports-icon{
      height: 28px;
    }
  
  
  }

  img{
    height: 50px;
    padding-top: 2px;
    padding-bottom: 2px;

    // margin-left: 20px;
  }
}
.sidebar-container{
  position: fixed;
  top: 0;
  left: -250px;
  height: 100vh;
  width: 100%;
  background-color: rgba($color: $black, $alpha: 0);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 10;
  &.show{
    left: 0;
    opacity: 1;
    visibility: visible;
  }

  .sidebar{
    height: 100%;
    background-color: $white;
    width: 250px;
    box-shadow: 0 0 5px 2px rgba($color: $black, $alpha: 0.2);
    padding-top: 120px;

    .sidebar-link{
      height: 40px;
      color: $black;
      font-weight: 500;
      text-decoration: none;
      margin: 0 20px;
      transition: all 0.3s;
      background-color: transparent;
      &:hover{
        background-color: rgba($color: $black, $alpha: 0.1)
      }
      &.active{
        background-color: rgba($color: $black, $alpha: 0.2);
      }

      .icon{
        height: auto;
        width: 20px;
        margin-right: 20px;
      }
    }
  }
}
