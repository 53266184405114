.pagination-container{
  background-color: $white;
  border-radius: 5px;
  height: 45px;
  padding: 0 10px;
  //box-shadow: 3px 3px 5px rgba($color: $black, $alpha: 0.1);

  .pagination-arrow{
    height: 25px;
    width: 25px;
    border-radius: 5px;
    border: 1px solid $black;
    padding: 0;
    svg{
      height: 12px;
      width: auto;
    }
    path{
      fill: $white;
    }
  }

  .pagination-count-container{
    margin: 0 20px;
  }
}