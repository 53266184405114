.report-container{
  .grid-row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .input-container{
    position: relative;

    label{
      font-weight: 600;
      margin-bottom: 8px;
    }

    input{
      padding: 0 10px;
      height: 100%;
    }
  }
}