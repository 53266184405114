.popup-container{
  position: fixed;
  inset: 0;
  background-color: rgba($color: $black, $alpha: 0.4);
  z-index: 2100;
  opacity: 0;
  visibility: hidden;
  padding: 20px;
  transition: all 0.3s;
  backdrop-filter: blur(20px);
  overflow: auto;
  display: grid;
  place-items: center;

  &.show{
    opacity: 1;
    visibility: visible;
  }

  .popup{
    background-color: $white;
    padding: 0 20px 40px 20px;
    border-radius: 5px;
    margin: 0 auto;
  }
  .popup-title{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
    margin: 0 -20px;
    padding: 20px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
    margin-bottom: 20px;
  }
}