.application-details-container{
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  .paper{
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .detail-row{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .detail-container{
    // margin-bottom: 20px;
    font-weight: 700;
    &:last-child{
      margin-bottom: 0;
    }

    .title{
      font-size: 14px;
      color: rgba($color: $black, $alpha: 0.7);
      width: 210px;
      flex-shrink: 0;
    }
    .value{
      color: rgba($color: $black, $alpha: 1);
      text-transform: uppercase;
      font-size: 14px;
    }

    input,
    select{
      height: 40px;
      border: 1px solid rgba($color: $black, $alpha: 0.2);
      padding: 0 10px;
      margin-right: 20px;
      border-radius: 5px;
      &:focus{
        outline: none;
      }

      &.has-error{
        border : 1px solid rgba($color: $error, $alpha: 0.8)
      }
    }

    textarea{
      border: 1px solid rgba($color: $black, $alpha: 0.2);
      padding: 10px;
      border-radius: 5px;
      &:focus{
        outline: none;
      }

      &:disabled{
        background-color: transparent;
        resize: none;
        padding: 0;
        color: $black;
        font-weight: bold;
      }
    }
  }

  .underwriting-sheet{
    .detail-container{
      font-size: 12px;
      .title{
        font-size: 12px;
        width: 175px;
      }
      .value{
        font-size: 12px;
      }
    }
  }

  .medical-details{
    .detail-container{
      .title{
        width: 270px;
      }
    }
  }

  .pre-underwriting-details{
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
    border-top: 1px solid rgba($color: $black, $alpha: 0.2);
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);

    table{
      td,th{
        padding: 4px 8px;
        font-size: 12px;
      }
    }
  }

  .amendment-edit-button{
    height: 40px;
    width: 40px;
    padding: 0;

    svg{
      path {
        fill: #fff;
      }
    }
  }
}

.medical-test-request-popup{
  width: 100%;
  max-width: 1000px;

  .d-grid{
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .custom-checkbox-label{
    height: 40px;
    border: 1px solid rgba($color: $black, $alpha: 0.2);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;

    span{
      height: 20px;
      width: 20px;
      border: 1px solid rgba($color: $black, $alpha: 0.2);
      margin-right: 10px;
      border-radius: 2px;
      position: relative;

      &::after{
        position: absolute;
        content: "";
        top: 10%;
        left: 10%;
        background-image: url(../static/icons/tick-icon-bold.svg);
        background-size: 100% 100%;
        height: 80%;
        width: 80%;
      }
    }

    input:checked{
      &+ span{
        background-color: $secondary-color;
      }
    }
  }

  .detail{
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;

    input,
    select{
      height: 40px;
      border: 1px solid rgba($color: $black, $alpha: 0.2);
      padding: 0 10px;
      margin-right: 20px;
      border-radius: 5px;
    }

    select{
      font-size: 14px;
    }
  }

  .medical-information-box{
    height: 120px;
    border-radius: 5px;
    border: 1px solid rgba($color: $black, $alpha: 0.2);
    padding: 10px;
  }
}

.comment-box-title{
  &+textarea{
    border: 1px solid rgba($color: $black, $alpha: 0.2);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
}

.download-document-box{
  height: 50px;
  border: 1px solid rgba($color: $black, $alpha: 0.2);
  padding: 0 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  &:last-child{
    margin-bottom: 0;
  }
}