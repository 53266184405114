$bg-gray : #ebebeb;
$white : #fff;
$black : #010118;
$error : #cc0000;
$success : #007000;
$warning : #cc7a00;
$info : #005ce6;
$primary-color : #00A590;
$secondary-color : #00A590;
$link-color:#00AC9B;
$brand-color-a:#00AC9B;