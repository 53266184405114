body{
  background-color: $bg-gray;
  font-family: 'Inter', sans-serif;
}
.site-container{
  padding: 120px 20px 20px 20px;
}
.home-container{
  padding: 20px;
  background-color: $white;  
  .paper{
    box-shadow: none;  
    margin: 0;  
  }
  box-shadow: 0px 0px 9px -3px rgba($color: $black, $alpha: 0.1);  
  border-radius: 5px;
}

.page-title{
  font-size: 22px;
  font-weight: 600;
  color:$primary-color;
}

button{
  height: 40px;
  border: none;
  cursor: pointer;
  background: $secondary-color;
  color: $white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba($color: $black, $alpha: 0.3);
  font-size: 18px;
  transition: all 0.3s;
  padding: 0 20px;

  &:disabled{
    opacity: 0.3;
    box-shadow: none;
    cursor: no-drop;
  }
  &:focus{
    outline: none;
  }

  &.button-sm{
    height: 30px;
    font-size: 12px;
  }
  &.button-bordered{
    border: 1px solid $black;
    background: transparent;
    color: $black;
    font-weight: 600;
    box-shadow: none;
    padding: 0 16px;
  }

  &.icon-button{
    height: 40px;
    width: 40px;
    padding: 0;
    
    svg{
      path{
        fill: #fff;
      }
    }
  }
}
.cursor-pointer{
  cursor: pointer;
}


.paper{
  background-color: $white;
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 9px -3px rgba($color: $black, $alpha: 0.1);
  margin-bottom: 20px;
  &.with-table{
    padding: 20px;
    margin:10px 10px 20px 10px;
    border:1px dashed #e1e1e1;
    background-color: #fdfdfd;
    th{
      padding: 0 0 .5rem 0;
    }
    td{
      padding:12px 0px;
    }
  }

  th{
    font-weight: 500;
    background-color: #fdfdfd;
  }
  td{
    border-style: dashed;
    background-color: #fdfdfd;    
    
  }
   tr:hover td{
    border-width: 1px 0px 1px 0px;
    border-color: #dbdbdb;
    border-style:solid;
    background-color: #ffffff;    


 
  }
  .app-status, .app-type{
    font-weight: 400;
    font-size:0.9rem;
    border-width:1px;
    border-style: solid;
    border-radius: 5px;
    padding:4px 10px 4px 10px;    
  }
  .status-draft{           
      color:#7b7b7b;
      background-color:#f6f6f6;
      border-color: #cfcfcf;
      box-shadow: 0px 1px 6px -5px #7b7b7b;   
    }
    .status-application-submitted{     
      color:#1c1c1c;
      background-color:#fffad5;
      border-color: #e0dbb2;
      box-shadow: 0px 1px 6px -5px #1c1c1c;    
    }
    .status-application-created{
      color:#3d142f;     
      background-color:#ffe3f6;
      border-color: rgb(232, 187, 218);
      box-shadow: 0px 1px 6px -5px #3d142f;      
    }
    .status-approval-received{
      color:#003751;     
      background-color:#dbf3ff;
      border-color: #c1eaff;
      box-shadow: 0px 1px 6px -5px #003751;      
    }
    .status-application-in-cancellation-process{
      color:#542803;     
      background-color:#fee3cb;
      border-color: #f6d1b1;
      box-shadow: 0px 1px 6px -5px #843d00;  
    }
   .status-policy-cancellation-rejected{
      color:#ffffff;     
      background-color:#292929;
      border-color: #212020;
      box-shadow: 0px 1px 6px -5px #292929;  
    }
    .status-policy-cancellation-approved{
      color:#ffffff;     
      background-color:#bd0303;
      border-color: #a00303;
      box-shadow: 0px 1px 6px -5px #810101;  
    }
    .status-policy-issued{
      color:#086337;     
      background-color:#deffef;    
      border-color:#a5edcb;  
      box-shadow: 0px 1px 6px -5px #086337;   
    }
    .type-housing{
      color:#161002;     
      background-color:#fff9ea;
      border-color: #f9eac4;
      box-shadow: 0px 1px 6px -5px #241a03;  
    }
    .type-personal{
      color:#031822;     
      background-color:#dbf3ff;
      border-color: #bbe5fa;
      box-shadow: 0px 1px 6px -5px #031822;   
    }
    
  
  &.with-search{
    padding: 10px;
    gap: 10px;

    input{
      height: 40px;
      width: 550px;
      border-radius: 5px;
      border: 1px solid rgba($color: $black, $alpha: 0.3);
      padding:0px 10px;

      &:focus{
        outline: none;
      }
    }
    &.two-columns-grid{
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      input{
        width: auto;
      }

      .nav-tab{
        height: 30px;
        width: 150px;
        background-color: $white;
        text-decoration: none;
        color: $black;
        border: 1px solid $secondary-color;
        &.active{
          background-color: $secondary-color;
          color: $white;
        }       
      }
    }
  }

  .paper-title{
    margin: -20px -20px 0;
    margin-bottom: 20px;
    padding : 10px 20px;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient($primary-color, $secondary-color);
    color: $white;
    border-radius: 5px 5px 0 0;
  }

}

.view-icon{
  path{
    fill: rgba($color: $black, $alpha: 0.5);
  }

  &:hover{
    path{
      fill: rgba($color: $black, $alpha: 0.8);
    } 
  }
}

.bold{
  font-weight: 700;
}
table{
  .not-attended{
    background-color: rgba($color: $black, $alpha: 0.1);
  }
}
select{
  appearance: none;
  -webkit-appearance: none;
  background-image: url(../static/icons/caret-down.svg);
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: 24px;
}
input,select,textarea{
  &:focus{
    outline: none;
  }
}

.breadcrumb{
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  
  .breadcrumb-item{
    a{
      text-decoration: none;
      color:$brand-color-a;
    }
    &.active{
      color: $black;
    }
  }
}

.loader-container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: $white, $alpha: 0.5);
  backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;

  &.show{
    opacity: 1;
    visibility: visible;
  }

  .loader{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 5px solid rgba($color: $black, $alpha: 0.2);
    border-top: 5px solid rgba($color: $black, $alpha: 1);
    animation: rotate 1s infinite ease-in-out;
  }

  @keyframes rotate {
    from{
      transform: rotate(0);
    }
    to{
      transform: rotate(360deg);
    }
  }
}

.date-input-container{
  position: relative;
  height: 40px;
  border: 1px solid rgba($color: $black, $alpha: 0.3);
  border-radius: 4px;
  padding-left: 10px;
  cursor: pointer;

  .value-container{
    border-right: 1px solid rgba($color: $black, $alpha: 0.3);
  }

  .icon-contaier{
    width: 40px;
  }

  &.disabled{
    background-color: rgba($color: $black, $alpha: 0.1);
    cursor: default;
  }
}

.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker{
  .react-datepicker__triangle{
    transform: translate(200px, 0) !important;
  }

  .react-datepicker__navigation{
    height: 30px;
    width: 30px;
    box-shadow: none;
    border: 1px solid rgba($color: $black, $alpha: 0.3);

    &-icon{
      &::before{
        height: 12px;
        width: 12px;
        top: 10px;
        border: none;
        background-image: url(../static/icons/arrow-up.svg);
        transform: rotate(90deg);
        background-size: 100%;
      }

      &--next{
        &::before{
          left: -3px;
        }
      }

      &--previous{
        &::before{
          transform: rotate(-90deg);
          right: -3px;
        }
      }
    }
  }

  a.react-datepicker__navigation--years-upcoming{
    top: 0;
    border: none;
    &::after{
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: url(../static/icons/arrow-up.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 12px;
    }
  }
  a.react-datepicker__navigation--years-previous{
    top: 0;
    border: none;
    &::after{
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: url(../static/icons/arrow-up.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 12px;
      transform: rotate(180deg);
    }
  }

  .react-datepicker__header__dropdown{
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 10px;
  }

  .react-datepicker__month-dropdown-container{
    border: 1px solid rgba($color: $black, $alpha: 0.3);
    width: 100px;
    text-align: left;
    padding-left: 5px;
    margin: 0;
    border-radius: 4px;
    position: relative;

    &--select{
      padding: 0;
      border: none;
      
      select{
        border: 1px solid rgba($color: $black, $alpha: 0.3);
        width: 100%;
        background-color: transparent;
        padding: 0 10px;
        border-radius: 4px;
        height: 40px;
      }
    }

    .react-datepicker__month-dropdown{
      left: 0;
      width: 100%;
      top: 0;

      .react-datepicker__month-option--selected_month{
        background-color: rgba($color: $black, $alpha: 0.8);
        color: $white;
        span{
          display: none;
        }
      }
    }

    .react-datepicker__month-read-view--down-arrow{
      right: 6px;
      top: 4px;
      border-width: 1px 1px 0 0;
      border-color: $black;
      height: 7px;
      width: 7px;
    }
  }
  .react-datepicker__year-dropdown-container{
    border: 1px solid rgba($color: $black, $alpha: 0.3);
    text-align: left;
    padding-left: 5px;
    margin: 0;
    border-radius: 4px;
    position: relative;

    &--select{
      padding: 0;
      border: none;

      select{
        border: 1px solid rgba($color: $black, $alpha: 0.3);
        width: 100%;
        background-color: transparent;
        padding: 0 10px;
        border-radius: 4px;
        height: 40px;
      }
    }

    .react-datepicker__year-dropdown{
      left: 0;
      width: 100%;
      top: 0;

      .react-datepicker__year-option--selected_year{
        background-color: rgba($color: $black, $alpha: 0.8);
        color: $white;
        span{
          display: none;
        }
      }
    }

    .react-datepicker__year-read-view--down-arrow{
      right: 6px;
      top: 4px;
      border-width: 1px 1px 0 0;
      border-color: $black;
      height: 7px;
      width: 7px;
    }
  }
}