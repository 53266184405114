.application-edit-container{
  
  .paper{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  .grid-row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .full-width{
    grid-column: span 3;
  }

  .input-container{
    position: relative;

    label{
      font-weight: 600;
      margin-bottom: 8px;
    }

    input,
    select{
      height: 40px;
      border: 1px solid rgba($color: $black, $alpha: 0.3);
      border-radius: 4px;
      padding: 0 10px;
      &:focus{
        border: 1px solid rgba($color: $black, $alpha: 0.8);
        outline: none;
      }

      &.has-error{
        border : 1px solid rgba($color: $error, $alpha: 0.8)
      }
    }

    .question-detail{
      border: 1px solid rgba($color: $black, $alpha: 0.3);
      border-radius: 4px;
      padding: 10px;
      margin-top: 10px;
      &:focus{
        outline: none;
      }
    }

    .tick-container{
      height: 40px;
      gap: 20px;
    }

    .custom-check-label{
      margin-bottom: 0;
      span{
        display: inline-block;
        height: 18px;
        width: 18px;
        border: 1px solid $secondary-color;
        border-radius: 2px;
        margin-right: 10px;
        position: relative;
        transition: all 0.3s;
        
        &::after{
          position: absolute;
          content: "";
          inset: 2px;
          background-image: url(../static/icons/tick-icon-bold.svg);
          background-size: 100% 100%;
          transform: scale(0);
          transition: all 0.3s;
        }
      }
      
      input{
        &:checked{
          &+span{
            background-color: $secondary-color;
            &::after{
              transform: scale(1);
            }
          }
        }
      }
    }

    .error-message{
      font-size: 12px;
      color: $error;
      position: absolute;
      left: 0;
      bottom: -18px;
      font-weight: 500;
    }
  }

  .topup-loan-header{
    background-color: $primary-color;
    color: $white;
  }
}