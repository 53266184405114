.login-form{
  background-color: $white;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 2px rgba($color: $black, $alpha: 0.1);
  padding: 40px;
  width: 450px;
  max-width: 100%;

  .title{
    font-size: 40px;
    margin-bottom: 40px;
  }
  
  label{
    font-weight: 600;
    margin-bottom: 8px;
  }
  input{
    margin-bottom: 20px;
    height: 50px;
    border: 1px solid rgba($color: $black, $alpha: 0.2);
    border-radius: 5px;
    padding: 0 10px;
    font-weight: 500;

    &:focus{
      outline: none;
    }
  }
  button{
    margin-top: 40px;
    height: 50px;
    font-size: 20px;
  }
}